import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
    const authToken = localStorage.getItem('authToken');
    if (authToken) config.headers.Authorization = 'Bearer ' + authToken;
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const unauthorized = [401, 419];
        if (unauthorized.includes(error.response?.status)) {
            localStorage.removeItem('authToken');
            window.location.href = '/';
        } else if (error.response?.status === 500) {
            /* toast.error(
                'Sorry, there was an error processing your request',
                {
                    toastId: 'error',
                }
            ); */
            return;
        } else {
            return Promise.reject(error);
        }
    }
);

export { axios };
