import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Text, Heading, Box, Container } from 'theme-ui';
import { setFieldIndex, setStep } from 'features/appSlice';
import { storeAnswersAsync } from 'features/appThunks';
import Scrollbars from '~/Common/Scrollbars';
import { LinkButton } from '~/Common/LinkButton';
import { Propeller, propellerSections } from '~/Steps/Propeller';
import { Field } from '~/Steps/Field';
import { scrollToElement } from 'utils/helpers';
import { StyledStep, StepWrapper, StepScroller } from './styled';
import { appSettings } from 'app/settings';
import { PropellerStatic } from 'assets/images';
import { PropellerProgress } from '../Propeller/PropellerProgress';
import { Fragment } from 'react';

const StepUncoupled = ({
    activeStep,
    activeStepIndex,
    activeFieldIndex,
    setFieldIndex,
    setStep,
    storeAnswersAsync,
    ...props
}) => {
    const resetRef = useRef(null);
    const stepRef = useRef(null);
    const fieldRefs = useRef([]);
    const { t } = useTranslation();
    const { submitForm } = useFormikContext();
    const showPropeller = activeStep?.showPropeller;
    const showPropellerDynamic =
        showPropeller && appSettings.propeller.type === 'dynamic';
    const showPropellerStatic =
        showPropeller && appSettings.propeller.type === 'static';
    const activeField = activeStep?.fields?.[activeFieldIndex] ?? null;

    const renderFields = () => {
        if (!Array.isArray(activeStep.fields)) {
            return null;
        }

        let lastType = null;

        return activeStep.fields.map((field, index) => {
            const showHeading = field.propellerType !== lastType;
            const type = propellerSections[field.propellerType];

            if (showHeading) {
                lastType = field.propellerType;
            }

            return (
                <Fragment key={`${index}${field.name}`}>
                    {showHeading && (
                        <Box
                            sx={{
                                mt:
                                    index === 0 &&
                                    appSettings.propeller.showStaticImage
                                        ? 0
                                        : 5,
                            }}
                        >
                            <Heading sx={{ mb: 2 }}>{type.name}</Heading>
                            <Text>{type.description}</Text>
                        </Box>
                    )}
                    <Field
                        key={index}
                        ref={(element) =>
                            addFieldRef({
                                index,
                                element,
                                field,
                            })
                        }
                        field={field}
                        isActive={true}
                        onNext={
                            index === lastStepIndex ? handleNext : null
                        }
                        afterChange={() => afterFieldChange(index)}
                    />
                </Fragment>
            );
        });
    };

    const afterFieldChange = (index) => {
        const currentField = fieldRefs.current?.[index];

        if (!currentField) {
            return;
        }

        const isFinal =
            currentField.index === activeStep.fields.length - 1;

        if (!isFinal) {
            scrollToElement(currentField.element.nextSibling);
        } else {
            index !== lastStepIndex && submitForm();
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        setStep(activeStepIndex - 1);
    };

    const handleNext = (e) => {
        e.preventDefault();
        submitForm();
    };

    useEffect(() => {
        const step = stepRef.current;
        if (step) {
            step.style.scrollBehavior = 'initial';
            step.scrollTo(0, 0);
            step.style.scrollBehavior = '';
        }
    }, [activeStep]);

    const addFieldRef = ({ index, element, field }) => {
        if (element) {
            // This is here to ensure fieldRefs are always cleared BEFORE refs are added
            // useLayoutEffect/useEffect seems to clear it afterwards in some instances
            if (resetRef.current !== activeStepIndex) {
                resetRef.current = activeStepIndex;
                fieldRefs.current = [];
            }
            fieldRefs.current[index] = {
                index,
                field,
                element,
            };
        }
    };

    useEffect(() => {
        const stepEl = stepRef.current;
        const onScroll = debounce((e) => {
            if (!stepRef.current) {
                return;
            }

            const currentScrollTop = stepRef.current.scrollTop;

            if (Array.isArray(fieldRefs.current)) {
                const reversed = [...fieldRefs.current].reverse();
                const currentField = reversed.find((field) =>
                    field.element
                        ? field.element.offsetTop -
                              field.element.parentElement.offsetTop -
                              200 <
                          currentScrollTop
                        : false
                );

                if (
                    currentField &&
                    currentField.index !== activeFieldIndex
                ) {
                    setFieldIndex(currentField.index);
                }
            }
        }, 15);

        if (stepEl) {
            stepEl.addEventListener('scroll', onScroll);

            return () => {
                stepEl.removeEventListener('scroll', onScroll);
            };
        }
    }, [activeFieldIndex, setFieldIndex, activeStep]);

    const lastStepIndex = activeStep
        ? activeStep.fields.findLastIndex((field) => !!field.visible)
        : -1;

    return (
        <>
            {activeField?.propellerType && (
                <PropellerProgress type={activeField.propellerType} />
            )}
            <Box
                sx={{
                    height: '55px',
                }}
            >
                {activeStepIndex > 0 && (
                    <LinkButton
                        icon="back"
                        sx={{
                            display: 'inline-flex',
                            zIndex: 999,
                            p: '20px',
                            '& svg': {
                                transition: 'transform 0.3s ease-in-out',
                            },
                            '&:hover svg': {
                                transform: 'translateX(-6px)',
                            },
                        }}
                        to="#"
                        onClick={handleBack}
                    >
                        {t('buttons.goBack')}
                    </LinkButton>
                )}
            </Box>
            {activeStep && (
                <StyledStep {...props}>
                    <StepScroller as={Scrollbars} ref={stepRef}>
                        <Container
                            sx={{
                                display: 'flex',
                                gap: 3,
                                pt: ['30px', null, null, 0],
                            }}
                        >
                            <Box
                                sx={{
                                    userSelect: 'none',
                                    mt: '30px',
                                }}
                            >
                                <Text
                                    sx={{
                                        display: 'block',
                                        color: 'primary',
                                        fontWeight: 600,
                                        mb: 3,
                                    }}
                                >
                                    {t('steps.indexPrefix')}{' '}
                                    {activeStepIndex + 1}
                                </Text>
                                <Heading
                                    as="h1"
                                    variant="h1"
                                    color="#fff"
                                    pb={16}
                                >
                                    {activeStep.title}
                                </Heading>
                                {activeStep.description && (
                                    <Text
                                        sx={{
                                            display: 'block',
                                            fontWeight: 400,
                                            fontSize: '20px',
                                            lineHeight: 1.4,
                                            pb: showPropellerStatic
                                                ? 3
                                                : 0,
                                        }}
                                    >
                                        {activeStep.description}
                                        {activeStep.name.toLowerCase() ===
                                            'financials' &&
                                            ' You may either manually enter the information or connect your accounting software to pull it in automatically.'}
                                    </Text>
                                )}
                            </Box>
                            {showPropellerStatic &&
                                appSettings.propeller.showStaticImage && (
                                    <PropellerStatic />
                                )}
                        </Container>
                        <StepWrapper>
                            <Container sx={{ position: 'relative' }}>
                                {showPropellerDynamic && (
                                    <Propeller
                                        sx={{ display: ['none', 'block'] }}
                                        type={activeField.propellerType}
                                    />
                                )}
                                <Box
                                    sx={{
                                        height: '100%',
                                        pl: showPropellerDynamic
                                            ? [0, '356px']
                                            : 0,
                                        pb: activeField?.propellerType
                                            ? '50px'
                                            : 0,
                                    }}
                                >
                                    {renderFields()}
                                </Box>
                            </Container>
                        </StepWrapper>
                    </StepScroller>
                </StyledStep>
            )}
        </>
    );
};

export const Step = connect(
    ({ app }) => ({
        activeStep: app.activeStep,
        activeStepIndex: app.activeStepIndex,
        activeFieldIndex: app.activeFieldIndex,
    }),
    { setFieldIndex, setStep, storeAnswersAsync }
)(StepUncoupled);
