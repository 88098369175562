import { Field as FormikField, useField } from 'formik';
import { Box } from 'theme-ui';
import { Error } from '~/Common/Error';

const FieldError = ({ error, errorSx = {} }) => {
    return error ? 
        <Error
            sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                margin: 0,
                ...errorSx,
            }}
        >
            {error}
        </Error> : <></>
}

export const Field = ({ name, boxSx = {}, errorSx = {}, separateError = false, ...props }) => {
    const [, { error }] = useField(name);

    return <>
        {separateError && <FieldError error={error} errorSx={errorSx} />}
        <Box sx={{ position: 'relative', ...boxSx }}>
            <FormikField name={name} {...props} />
            {!separateError && <FieldError error={error} errorSx={errorSx} />}
        </Box>
    </>
};
