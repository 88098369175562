export const scrollToElement = (element, wrapper) => {
    wrapper = wrapper ?? document.getElementById('scroller');

    if (wrapper && element) {
        wrapper.scrollTo(
            0,
            element.offsetTop -
                element.parentElement.offsetTop -
                (element.clientHeight > wrapper.clientHeight ? 30 : 0)
        );
    }
};
