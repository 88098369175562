import { t } from 'i18next';
import { connect } from 'react-redux';
import { Box } from 'theme-ui';
import { StyledStepsNav, StepNavItem } from './styled';

const StepsNavUncoupled = ({
    isComplete,
    activeStepIndex,
    steps,
    ...props
}) => {
    if (!Array.isArray(steps.data)) {
        return null;
    }

    return (
        <StyledStepsNav {...props}>
            {Array.isArray(steps.data) &&
                steps.data.map((step, index) => (
                    <StepNavItem
                        key={step.name}
                        data-index={index + 1}
                        $isActive={
                            !isComplete && index === activeStepIndex
                        }
                    >
                        <Box as="span">{step.title}</Box>
                    </StepNavItem>
                ))}
            <StepNavItem
                data-index={steps.data.length + 1}
                $isActive={isComplete}
            >
                <Box as="span">{t('steps.results.label')}</Box>
            </StepNavItem>
        </StyledStepsNav>
    );
};

export const StepsNav = connect(({ app }) => ({
    activeStepIndex: app.activeStepIndex,
    isComplete: app.submission.isComplete,
    steps: app.steps,
}))(StepsNavUncoupled);
