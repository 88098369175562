import styled from 'styled-components';
import { Box } from 'theme-ui';

const ErrorWrapper = styled(Box)`
    display: block;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.error};
`;

export const Error = ({ children, sx, ...props }) => (
    <ErrorWrapper
        sx={{
            margin: '25px 0 10px 0',
            ...sx,
        }}
        {...props}
    >
        {children}
    </ErrorWrapper>
);
