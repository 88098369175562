import { PropellerProgressStyled } from './styled';
import { propellerSections } from '.';
import { Box, Flex } from 'theme-ui';

const sections = Object.entries(propellerSections).map((s) => ({
    key: s[0],
    ...s[1],
}));

export const PropellerProgress = ({ type, ...props }) => {
    return (
        <PropellerProgressStyled {...props}>
            <Flex
                sx={{
                    gap: 3,
                    p: 3,
                    textAlign: 'center',
                    justifyContent: 'center',
                }}
            >
                {sections.map((section, index) => {
                    const isActive = section.key === type;
                    return (
                        <Box key={index}>
                            <Box
                                sx={{
                                    transition: 'opacity 0.5s ease',
                                    fontSize: '16px',
                                    fontWeight: 300,
                                }}
                                style={{ opacity: isActive ? 1 : 0 }}
                            >{`${index + 1}/${sections.length}`}</Box>
                            <Box
                                sx={{
                                    background: '#00AEEF',
                                    transition: 'opacity 0.5s ease',
                                    borderRadius: '3px',
                                    width: '108px',
                                    height: '4px',
                                    maxWidth: '100%',
                                    mt: 1,
                                }}
                                style={{
                                    opacity: isActive ? 1 : 0.2,
                                }}
                            />
                        </Box>
                    );
                })}
            </Flex>
        </PropellerProgressStyled>
    );
};
