export const financeTypes = [
    {
        name: 'sales-revenue',
        label: 'Sales / Revenue',
    },
    {
        name: 'cost-of-goods-sold',
        label: 'Cost of Goods Sold',
    },
    {
        name: 'gross-profit',
        label: 'Gross Profit',
        calculated: true,
    },
    {
        name: 'salary-wages',
        label: 'Salary & Wages',
    },
    {
        name: 'other-overhead',
        label: 'Other Overhead',
    },
    {
        name: 'net-profit',
        label: 'Net Profit',
        calculated: true,
    },
    {
        name: 'cash-on-hand',
        label: 'Cash On Hand',
        spaceBefore: true,
    },
    {
        name: 'debt',
        label: 'Debt',
    },
];
