import { StyledImageField } from './styled';
import { Heading, Box, Image } from 'theme-ui';
import { Icon } from '~/Common/Icon';

export const ImageField = ({ icon, title, src, children, ...props }) => {
    return (
        <StyledImageField>
            {(icon || title) && (
                <Box
                    sx={{
                        display: 'flex',
                        gridGap: '22px',
                        alignItems: 'center',
                        mb: 3,
                    }}
                >
                    {icon && <Icon icon={icon} />}
                    {title && (
                        <Heading
                            sx={{
                                color: '#fff',
                                fontSize: '18px',
                            }}
                        >
                            {title}
                        </Heading>
                    )}
                </Box>
            )}
            <Image src={src} sx={{ display: 'block', mx: 'auto' }} />
            {children && <Box sx={{ mt: 4 }}>{children}</Box>}
        </StyledImageField>
    );
};
