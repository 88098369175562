import styled from 'styled-components';

export const GetReportFormStyled = styled.div`
    position: relative;
    padding: 48px 40px;
    border: 2px solid #00aeef;
    border-radius: 8px;
    margin-top: 70px;
    text-align: center;
`;
