/*
 * Ported from react-custom-scroller & updated to use styled-components
 *
 * https://github.com/closeio/react-custom-scroller
 */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useCustomScroller from './useCustomScroller';
import {
    ScrollbarInner,
    ScrollbarMain,
    ScrollbarTrack,
    ScrollbarWrapper,
    ScrollbarContent,
} from './styled';

const Scrollbars = forwardRef(
    ({ scrollDisabled, children, ...props }, ref) => {
        const [wrapperProps, scrollerProps, trackProps] =
            useCustomScroller(children, ref, { disabled: scrollDisabled });

        return (
            <ScrollbarMain {...props}>
                <ScrollbarWrapper {...wrapperProps}>
                    <ScrollbarInner id="scroller" {...scrollerProps}>
                        <ScrollbarContent>{children}</ScrollbarContent>
                    </ScrollbarInner>
                </ScrollbarWrapper>
                <ScrollbarTrack className="track" {...trackProps} />
            </ScrollbarMain>
        );
    }
);

Scrollbars.propTypes = {
    scrollDisabled: PropTypes.bool,
    innerClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Scrollbars;
