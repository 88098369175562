import { connect } from 'react-redux';
import { Select } from './Select';

const IndustriesSelectUncoupled = ({ industries, ...props }) => {
    return (
        <Select
            placeholder="Please Select Your Industry"
            options={industries.data ?? []}
            {...props}
        />
    );
};

export const IndustriesSelect = connect(({ app }) => ({
    industries: app.industries,
}))(IndustriesSelectUncoupled);
