import styled from 'styled-components';

export const StyledField = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    justify-content: center;
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0.5')};
    transition: opacity 0.25s ease-in-out;
    user-select: none;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 18px;
`;
