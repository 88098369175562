import NumberFormat from 'react-number-format';
import { Field } from 'theme-ui';

export const Number = ({
    value,
    thousandSeparator = ',',
    formatted = false,
    ...props
}) => {
    return (
        <NumberFormat
            customInput={Field}
            thousandSeparator={formatted ? thousandSeparator : ''}
            value={value}
            allowEmptyFormatting={true}
            {...props}
        />
    );
};
