import styled from 'styled-components';
import { Box, Card } from 'theme-ui';

export const CardStyled = styled(Card)`
    padding: 40px 24px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.015) 100%
    );
    border-radius: 8px;
`;

export const CardBody = styled(Box)``;
