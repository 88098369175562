import { financeTypes } from 'constants/financials';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { t } from 'i18next';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { Box, Button, Heading, Text } from 'theme-ui';
import { Icon } from '~/Common/Icon';
import { Currency } from '~/Fields/Currency';
import {
    FinancialHistoryStyled,
    FinancialHistoryHeader,
    HistoryTable,
    HistoryTableBody,
    HistoryRow,
    HistoryColumn,
    SpacerRow,
} from './styled';

const getColumns = () => ({
    '3 Years Ago': 0,
    '2 Years Ago': 0,
    'Last Year': 0,
    'Year To Date': 0,
});

export const FinancialHistory = ({
    title,
    value,
    onChange,
    onBlur,
    ...props
}) => {
    const isMobile = useBreakpoint(0);

    useEffect(() => {
        if (value === undefined) {
            const newValue = {};

            financeTypes.forEach((type) => {
                if (!type.calculated) {
                    newValue[type.name] = getColumns();
                }
            });

            onChange(newValue);
        }
    }, [value, onChange]);

    const calculateValue = ({ year, name }) => {
        const grossProfit =
            value['sales-revenue'][year] -
            value['cost-of-goods-sold'][year];

        switch (name) {
            case 'gross-profit':
                return grossProfit;
            case 'net-profit':
                return (
                    grossProfit -
                    value['salary-wages'][year] -
                    value['other-overhead'][year]
                );
            default:
                return 0;
        }
    };

    const setYearValue = (type, year, newValue) => {
        onChange({
            ...value,
            [type]: {
                ...value[type],
                [year]: parseInt(newValue),
            },
        });
    };

    if (!value || !Object.values(value).length) {
        return null;
    }

    return (
        <FinancialHistoryStyled {...props}>
            <FinancialHistoryHeader>
                <Box
                    sx={{
                        maxWidth: '450px',
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '28px',
                            color: '#fff',
                            fontWeight: 400,
                        }}
                    >
                        {title ?? t('fields.financialHistory.title')}
                    </Heading>
                    <Text
                        sx={{
                            fontSize: 13,
                            color: '#c0c0c9',
                        }}
                    >
                        {t('fields.financialHistory.disclaimer')}
                    </Text>
                </Box>
                <Button
                    disabled
                    sx={{ position: 'relative', textTransform: 'none' }}
                >
                    <Icon icon="add" />
                    {t('fields.financialHistory.connectSoftware')}
                    <Icon
                        icon="comingSoon"
                        sx={{
                            position: 'absolute',
                            right: '-15px',
                            top: '-20px',
                        }}
                    />
                </Button>
            </FinancialHistoryHeader>
            <HistoryTable>
                <HistoryTableBody>
                    <HistoryRow>
                        {!isMobile && <HistoryColumn />}
                        {Object.keys(Object.values(value)[0]).map(
                            (year) => (
                                <HistoryColumn key={year} $header={true}>
                                    {year}
                                </HistoryColumn>
                            )
                        )}
                    </HistoryRow>
                    {financeTypes.map((row, index) => (
                        <Fragment key={index}>
                            {row.spaceBefore && <SpacerRow />}
                            {isMobile && (
                                <HistoryRow $header={true}>
                                    <HistoryColumn
                                        $header={true}
                                        colSpan={4}
                                    >
                                        {row.label}
                                    </HistoryColumn>
                                </HistoryRow>
                            )}
                            <HistoryRow
                                key={index}
                                highlight={row.calculated}
                            >
                                {!isMobile && (
                                    <HistoryColumn
                                        $header={true}
                                        $minWidth="216px"
                                    >
                                        {row.label}
                                    </HistoryColumn>
                                )}
                                {Object.entries(getColumns()).map(
                                    (entry) => (
                                        <HistoryColumn key={entry[0]}>
                                            <Currency
                                                variant="transparent"
                                                value={
                                                    row.calculated
                                                        ? calculateValue({
                                                              name: row.name,
                                                              year: entry[0],
                                                          })
                                                        : value[row.name][
                                                              entry[0]
                                                          ]
                                                }
                                                disabled={row.calculated}
                                                onValueChange={({
                                                    value,
                                                }) => {
                                                    !row.calculated &&
                                                        setYearValue(
                                                            row.name,
                                                            entry[0],
                                                            value
                                                        );
                                                }}
                                            />
                                        </HistoryColumn>
                                    )
                                )}
                            </HistoryRow>
                        </Fragment>
                    ))}
                </HistoryTableBody>
            </HistoryTable>
        </FinancialHistoryStyled>
    );
};
