import styled from 'styled-components';

export const StyledImageField = styled.div`
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.015) 100%
    );
    padding: 30px;
    border-radius: 8px;
    margin-top: 32px;
    color: #fff;
    font-size: 16px;
    line-height: 22.4px;
`;
