import { useEffect, useState } from 'react';
import { Heading } from 'theme-ui';
import { PropellerImage } from './PropellerImage';
import { PropellerStyled } from './styled';

export const propellerSections = {
    financials: {
        name: 'Financials',
        description: 'The Engine of the Business. ',
    },
    sales: {
        name: 'Sales',
        description: 'Creating Revenue Growth.',
    },
    marketing: {
        name: 'Marketing',
        description: 'Sustaining A Lead Engine.',
    },
    leadership: {
        name: 'Leadership',
        description: 'Duplicating Yourself To Scale',
    },
    recruiting: {
        name: 'Recruiting',
        description: 'Identifying Top Talent',
    },
    productivity: {
        name: 'Productivity',
        description: "Connecting Time & KPI's",
    },
};

export const Propeller = ({ type, ...props }) => {
    const [state, setState] = useState({});

    useEffect(() => {
        if (propellerSections[type]) {
            setState(propellerSections[type]);
        }
    }, [type]);

    return (
        <PropellerStyled {...props}>
            <Heading
                sx={{
                    color: '#00AEEF',
                    fontSize: '28px',
                    fontWeight: 400,
                    mb: '24px',
                }}
            >
                {state.name ?? ''}
            </Heading>
            <PropellerImage type={type} />
        </PropellerStyled>
    );
};
