import { resumeSubmissionAsync } from 'features/appThunks';
import { useQuery } from 'hooks/useQuery';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ResumeProviderUncoupled = ({
    children,
    resumeSubmissionAsync,
    ...props
}) => {
    const [loading, setLoading] = useState(true)

    const history = useHistory()
    const query = useQuery()

    useEffect(() => {
        const signature = query.get('signature')
        const uuid = query.get('uuid')
        const utmSource = query.get('utm_source')

        if (signature || utmSource) {
            // These need to be in alphabetical order
            resumeSubmissionAsync({
                utm_source: utmSource,
                uuid: uuid,
                signature: signature,
            })
                .unwrap()
                .then(({ data }) => {
                    if (data?.entry?.current_step === 'finalised') {
                        toast.error('This submission has already been finalized.');
                        history.push('/');
                    }
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [resumeSubmissionAsync, history, query]);

    return loading ? <></> : children
};

export const ResumeProvider = connect(
    ({ app }) => ({}),
    {
        resumeSubmissionAsync,
    }
)(ResumeProviderUncoupled);
