import { t } from 'i18next';
import { Box, Heading, Text } from 'theme-ui';
import { Benchmark } from '~/Common/Benchmark';
import { Icon } from '~/Common/Icon';
import { BenchmarkHeader } from './styled';

export const IndustryBenchmark = ({ sx, ...props }) => {
    return (
        <Box
            sx={{ ...sx, userSelect: 'none', pointerEvents: 'none' }}
            {...props}
        >
            <BenchmarkHeader>
                <Icon icon="chart" />
                <Box>
                    <Heading sx={{ fontSize: '18px' }}>
                        {t(
                            'pages.results.complete.industryBenchmark.heading'
                        )}
                    </Heading>
                    <Text sx={{ fontSize: '16px', fontWeight: 300 }}>
                        {t(
                            'pages.results.complete.industryBenchmark.content'
                        )}
                    </Text>
                </Box>
            </BenchmarkHeader>
            <Benchmark />
        </Box>
    );
};
