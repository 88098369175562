import { useEffect, useState, useRef } from 'react';
import { Step } from '~/Steps/Step';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { setComplete, setStep } from 'features/appSlice';
import {
    getCurrentEntryAsync,
    getQuestionsAsync,
    storeAnswersAsync,
} from 'features/appThunks';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Loading } from '~/Common/Loading';

export const StepsUncoupled = ({
    setComplete,
    getQuestionsAsync,
    storeAnswersAsync,
    steps,
    setStep,
    activeStep,
    activeStepIndex,
    isComplete,
    canNavigate,
    submission,
}) => {
    const history = useHistory();
    const [isLoaded, setLoaded] = useState(false);
    const formikRef = useRef({});

    useEffect(() => {
        if (isComplete && canNavigate) {
            history.push('/results');
        }
    }, [isComplete, canNavigate, history]);

    useEffect(() => {
        if (!isLoaded && submission.status === 'fulfilled') {
            setLoaded(true);
            if (
                Object.entries(submission.data?.answers ?? {}).length &&
                formikRef.current
            ) {
                formikRef.current.setValues(submission.data.answers);
            }
        }
    }, [submission, isLoaded]);

    useEffect(() => {
        if (steps.status === 'idle') {
            getQuestionsAsync().then(() => setStep(0));
        }
    }, [activeStepIndex, steps.status, getQuestionsAsync, setStep]);

    const handleSubmit = async (values, { setErrors }) => {
        if (steps.status === 'fulfilled') {
            try {
                await storeAnswersAsync({
                    step: activeStep.name,
                    answers: activeStep.fields.reduce((answers, field) => {
                        answers[field.name] = values?.[field.name] ?? '';
                        return answers;
                    }, {}),
                }).unwrap();

                if (steps.data[activeStepIndex + 1]) {
                    setStep(activeStepIndex + 1);
                } else {
                    setComplete(true);
                }
            } catch (e) {
                toast.error(e?.data?.message);

                if (e.data.errors) {
                    setErrors(
                        Object.entries(e.data.errors).reduce(
                            (errors, e) => {
                                errors[e[0].substring(8)] = e[1];
                                return errors;
                            },
                            {}
                        )
                    );
                }
            }
        }
    };

    if (!submission.data) {
        return <Loading />;
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{}}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Step />
            </Formik>
        </>
    );
};

export const Steps = connect(
    ({ app }) => ({
        canNavigate: app.canNavigate,
        isComplete: app.submission.isComplete,
        steps: app.steps,
        submission: app.submission,
        activeStep: app.activeStep,
        activeStepIndex: app.activeStepIndex,
    }),
    {
        setComplete,
        getQuestionsAsync,
        getCurrentEntryAsync,
        storeAnswersAsync,
        setStep,
    }
)(StepsUncoupled);
