import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import 'app/translations';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Turn off console logs for production
if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
}
