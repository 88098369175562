import { connect } from 'react-redux';
import styled from 'styled-components';

export const StyledStep = styled.div`
    padding: 15px 15px 0 15px;
`;

export const StepWrapper = styled.div`
    position: relative;
`;

export const StepScroller = connect(
    ({ app }) => ({
        headerHeight: app.headerHeight,
    }),
    null,
    null,
    { forwardRef: true }
)(styled.div`
    height: calc(100vh - ${({ headerHeight }) => headerHeight + 72}px);
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #00aeef;
        z-index: 9999;
        opacity: 0.4;
    }
`);
