import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Routes } from 'app/routes';
import { store } from 'app/store';
import { theme } from 'app/theme';
import { ThemeProvider } from 'context/ThemeProvider';
import { Toaster } from 'react-hot-toast';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <Toaster toastOptions={{ style: theme.toast }} />
                    <Routes />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    );
};

export default App;
