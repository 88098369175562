import { StepsHeaderStyled, LogoLink } from './styled';
import { Logo } from 'assets/images';
import { LinkButton } from '~/Common/LinkButton';
import { StepsNav } from '~/Steps/StepsNav';
import { useEffect } from 'react';
import { useRef } from 'react';
import { setHeaderHeight } from 'features/appSlice';
import { connect } from 'react-redux';
import { t } from 'i18next';

const StepsHeaderUncoupled = ({
    isFinalised,
    headerHeight,
    setHeaderHeight,
}) => {
    const headerRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (headerRef.current?.offsetHeight !== headerHeight) {
                setHeaderHeight(headerRef.current?.offsetHeight);
            }
        });
        resizeObserver.observe(headerRef.current);
        return () => resizeObserver.disconnect();
    }, [headerHeight, setHeaderHeight]);

    return (
        <StepsHeaderStyled ref={headerRef}>
            <LogoLink to="/">
                <Logo sx={{ maxHeight: '50px', width: 'auto' }} />
            </LogoLink>
            <LinkButton
                to="/"
                icon="cross"
                iconPosition="right"
                sx={{ flex: '20%', justifyContent: 'right' }}
            >
                {t('buttons.close')}
            </LinkButton>
            {!isFinalised && <StepsNav />}
        </StepsHeaderStyled>
    );
};

export const StepsHeader = connect(
    ({ app }) => ({
        isFinalised: app.submission.isFinalised,
        headerHeight: app.headerHeight,
    }),
    { setHeaderHeight }
)(StepsHeaderUncoupled);
