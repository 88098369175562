import styled from 'styled-components';
import { Icon } from '~/Common/Icon';
import { Link } from 'react-router-dom';
import { Box } from 'theme-ui';

const LinkButtonStyled = styled((props) => <Box as={Link} {...props} />)`
    display: flex;
    font-family: ${({ theme }) => theme.fonts.input};
    grid-gap: 17px;
    font-size: 14px;
    font-weight: 300;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    background: none;
    border: none;
    user-select: none;

    &:hover .buttonText::after {
        content: '';
        width: 100%;
    }
`;

const LinkButtonText = styled.div`
    position: relative;

    &::after {
        content: '';
        display: inline-block;
        top: 100%;
        height: 1px;
        width: 0;
        left: 0;
        position: absolute;
        background-color: #fff;
        transition: 0.2s width ease-in-out;
    }
`;

export const LinkButton = ({ children, icon, iconPosition, ...props }) => {
    const iconAfter =
        typeof iconPosition === 'string' &&
        iconPosition.toLowerCase() === 'right';
    return (
        <LinkButtonStyled {...props}>
            {!iconAfter && icon && <Icon icon={icon} />}
            <LinkButtonText className="buttonText">
                {children}
            </LinkButtonText>
            {iconAfter && icon && <Icon icon={icon} />}
        </LinkButtonStyled>
    );
};
