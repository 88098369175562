import { Redirect, Route, Switch } from 'react-router-dom';
import { Steps } from 'pages/Steps';
import { Results } from 'pages/Results';
import { StepsLayout, ContainerLayout } from 'layouts';
import { Intro } from 'pages/Intro';
import { Partner } from 'pages/Partner';
import { ResumeProvider } from 'context/ResumeProvider';

export const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact>
                <ContainerLayout>
                    <Intro />
                </ContainerLayout>
            </Route>
            <Route path="/introduction" exact>
                <ContainerLayout>
                    <Intro step={2} />
                </ContainerLayout>
            </Route>
            <Route path="/partner" exact>
                <ContainerLayout>
                    <Partner />
                </ContainerLayout>
            </Route>
            <Route path="*">
                <ResumeProvider>
                    <StepsLayout>
                        <Switch>
                            <Route path="/results" component={Results} exact />
                            <Route path="/submission" component={Steps} />
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </StepsLayout>
                </ResumeProvider>
            </Route>
        </Switch>
    );
};
