import { cancelSubmission } from 'features/appSlice';
import {
    getCurrentEntryAsync,
    getIndustriesAsync,
    saveProgressAsync,
} from 'features/appThunks';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouterPrompt } from '~/Common/RouterPrompt';
import { StepsHeader } from '~/Steps/StepsHeader';
import { StepsLayoutStyled, StepsBody } from './styled';

export const StepsLayoutUncoupled = ({
    children,
    bodySx,
    canNavigate,
    cancelSubmission,
    submission,
    getCurrentEntryAsync,
    getIndustriesAsync,
    industriesStatus,
    saveProgressAsync,
    ...props
}) => {
    useEffect(() => {
        if (submission === null) {
            getCurrentEntryAsync();
        }
    }, [submission, getCurrentEntryAsync]);

    useEffect(() => {
        if (industriesStatus === 'idle') {
            getIndustriesAsync();
        }
    }, [industriesStatus, getIndustriesAsync]);

    return (
        <>
            <RouterPrompt
                when={!canNavigate}
                onOK={(email) => {
                    saveProgressAsync(email);
                    return true;
                }}
            />
            <StepsLayoutStyled {...props}>
                <StepsHeader />
                <StepsBody sx={bodySx}>{children}</StepsBody>
            </StepsLayoutStyled>
        </>
    );
};

export const StepsLayout = connect(
    ({ app }) => ({
        submission: app.submission.data,
        industriesStatus: app.industries.status,
        canNavigate: app.canNavigate,
    }),
    {
        cancelSubmission,
        getCurrentEntryAsync,
        getIndustriesAsync,
        saveProgressAsync,
    }
)(StepsLayoutUncoupled);
