import ReactSelect from 'react-select';
import { Icon } from '~/Common/Icon';
import { Box } from 'theme-ui';

const customStyles = {
    option: (provided) => ({
        ...provided,
        padding: 20,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '15px 24px',
        fontSize: 18,
    }),
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused
            ? '2px solid #00AEEF'
            : '2px solid rgba(217, 217, 222, 0.5)',
        background: 'transparent',
        borderRadius: 16,
        boxShadow: 'none',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    menuList: (provided) => ({
        ...provided,
        color: '#000',
    }),
    input: (provided) => ({
        ...provided,
        color: '#dedede',
    }),
};

const components = {
    DropdownIndicator: () => {
        return (
            <Box px="35px">
                <Icon icon="dropdown" />
            </Box>
        );
    },
};

export const Select = ({
    value,
    options,
    menuShouldScrollIntoView,
    isSearchable = false,
    field,
    form,
    ...props
}) => {
    const findOption = (name) => {
        if (!Array.isArray(options)) {
            return undefined;
        }
        return options.find((option) => option.value === name);
    };

    return (
        <ReactSelect
            components={components}
            styles={customStyles}
            options={options}
            menuShouldScrollIntoView={menuShouldScrollIntoView === true}
            value={typeof value !== 'object' ? findOption(value) : value}
            isSearchable={isSearchable}
            menuPlacement="auto"
            {...(form && field ? { onChange: value => form.setFieldValue(field.name, value) } : {})}
            {...props}
        />
    );
};
