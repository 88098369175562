import { Heading } from 'theme-ui';
import { Icon } from '../Icon';
import { CardStyled, CardBody } from './styled';

export const Card = ({
    title,
    icon,
    iconSize,
    children,
    bsx,
    titleSx,
    ...props
}) => {
    return (
        <CardStyled {...props}>
            {title && (
                <Heading
                    sx={{
                        display: 'flex',
                        flexDirection: ['column', 'row'],
                        alignItems: ['flex-start', 'center'],
                        gridGap: '13px',
                        fontSize: ['26px', '34px'],
                        mb: '36px',
                        ...titleSx,
                    }}
                >
                    {icon && <Icon icon={icon} size={iconSize} />}
                    {title}
                </Heading>
            )}
            <CardBody sx={bsx}>{children}</CardBody>
        </CardStyled>
    );
};
