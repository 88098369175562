import { Box } from 'theme-ui';
import ReactMarkdown from 'react-markdown';

export const Markdown = ({
    content,
    ...props
}) => {
    return <Box sx={{
        '> ul': {
            pl: 0,
            textAlign: 'left',
            listStyle: 'none',
        },
        '> ul li': {
            position: 'relative',
            pl: 4,
            mb: 3
        },
        '> ul li:before': {
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L15 1' stroke='%2300AEEF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
            content: '""',
            position: 'absolute',
            left: 0,
            top: '8px',
            width: '20px',
            height: '20px'
        }
    }}>
        <ReactMarkdown>
            {content}
        </ReactMarkdown>
    </Box>
};
