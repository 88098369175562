import styled from 'styled-components';

export const FinancialHistoryStyled = styled.div`
    padding: 30px 24px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.015) 100%
    );
    border-radius: 8px;
    margin-bottom: 76px;
`;

export const FinancialHistoryHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        flex-direction: column;
        gap: 32px;
    }
`;

export const HistoryTable = styled.table`
    width: 100%;
    margin-top: 52px;
    color: #fff;
    border-collapse: collapse;
`;

export const SpacerRow = styled.tr`
    height: 32px;
`;

export const HistoryRow = styled.tr`
    ${({ highlight }) =>
        highlight ? 'background: rgba(0, 174, 239, 0.2);' : ''}

    ${({ $header }) =>
        $header &&
        `
        font-weight: 600;

        td {
            padding-top: 32px;
            color: #00aeef;
        }
    `}

    &:first-child td {
        border-top: 0;
    }
`;

export const HistoryColumn = styled.td`
    font-size: 16px;
    padding: 8px;
    border: 1px solid rgba(245, 245, 246, 0.1);

    ${({ $header }) =>
        $header &&
        `
        font-weight: 600;
    `}

    ${({ $minWidth }) =>
        $minWidth &&
        `
        min-width: ${$minWidth};
    `}

    &:first-child {
        border-left: 0;
    }

    &:last-child {
        border-right: 0;
    }
`;

export const HistoryTableBody = styled.tbody``;
