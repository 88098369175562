import { Box, Flex, Text } from 'theme-ui';
import { Range as ReactRange } from 'react-range';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Icon } from './Icon';
import { forwardRef } from 'react';

const dotSx = {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '4px',
    height: '4px',
    bg: '#fff',
    opacity: '0.5',
    borderRadius: '50%',
    top: '4px',
    zIndex: 999,
    pointerEvents: 'none',
    userSelect: 'none',
};

const Thumb = forwardRef(({ text, index, ...props }, ref) => (
    <Flex
        {...props}
        ref={ref}
        sx={{
            bottom: index === 0 ? '-30px' : '-50px',
            alignItems: 'center',
            flexDirection: 'column',
            '&::after': {
                content: '""',
                display: 'block',
                width: '1px',
                height: index === 0 ? '40px' : '80px',
                background: '#fff',
            },
            '&::before': {
                position: 'absolute',
                content: '""',
                display: 'block',
                width: '7px',
                height: '7px',
                borderRadius: '50%',
                background: '#fff',
                bottom: 0,
                left: '16px',
            },
        }}
    >
        <Icon icon="userCircle" />
        <Box
            sx={{
                position: 'absolute',
                left: '100%',
                top: '10px',
                pl: 2,
                width: '150px',
                fontWeight: 600,
                fontSize: 14,
            }}
        >
            {text}
        </Box>
    </Flex>
));

const BenchmarkUncoupled = ({ calculations, ...props }) => {
    return (
        <Box sx={{ pt: '64px' }}>
            <Box
                sx={{
                    position: 'relative',
                    mt: 3,
                    px: '10px',
                    background: `rgba(255,255,255,0.2)`,
                    borderRadius: '12px',
                    '&::before': {
                        ...dotSx,
                        left: '8px',
                    },
                    '&::after': {
                        ...dotSx,
                        right: '8px',
                    },
                }}
            >
                <ReactRange
                    step={1}
                    min={0}
                    max={100}
                    allowOverlap={true}
                    values={[
                        Math.floor(calculations.business_value_percentage),
                        Math.ceil(calculations.potential_value_percentage),
                    ]}
                    renderTrack={({ props, children }) => (
                        <Box
                            {...props}
                            style={{
                                ...props.style,
                            }}
                            sx={{
                                height: '12px',
                                width: '100%',
                                '&::before': {
                                    ...dotSx,
                                    left: '20%',
                                    marginLeft: '-2px',
                                },
                                '&::after': {
                                    ...dotSx,
                                    right: '20%',
                                    marginRight: '-2px',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    ...dotSx,
                                    left: '40%',
                                    marginLeft: '-2px',
                                }}
                            >
                                &nbsp;
                            </Box>
                            <Box
                                sx={{
                                    ...dotSx,
                                    left: '60%',
                                    marginLeft: '-2px',
                                }}
                            >
                                &nbsp;
                            </Box>
                            {children}
                        </Box>
                    )}
                    renderThumb={({ props, index }) => (
                        <Thumb
                            {...props}
                            index={index}
                            text={
                                index === 0 ? 'Your Business' : 'Potential'
                            }
                        />
                    )}
                    {...props}
                />
            </Box>
            <Flex
                sx={{
                    mt: 4,
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Text variant="boldheading">
                        {t('A$')}
                        {calculations.minimum_industry_value}
                    </Text>
                    <Text>({t('Minimum industry value')})</Text>
                </Box>
                <Box
                    sx={{
                        textAlign: 'right',
                    }}
                >
                    <Text variant="boldheading">
                        {t('Your value potential')}
                    </Text>
                    <Text>({t('Maximum industry value')})</Text>
                </Box>
            </Flex>
        </Box>
    );
};

export const Benchmark = connect(({ app }) => ({
    calculations: app.submission.data.calculations,
}))(BenchmarkUncoupled);
