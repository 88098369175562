import NumberFormat from 'react-number-format';
import { Field } from 'theme-ui';

export const Currency = ({
    value,
    prefix = '$',
    thousandSeparator = true,
    ...props
}) => {
    return (
        <NumberFormat
            prefix={prefix}
            customInput={Field}
            thousandSeparator={thousandSeparator}
            value={value}
            allowEmptyFormatting={true}
            {...props}
        />
    );
};
