import React from 'react';
import styled from 'styled-components';
import allIcons from 'assets/icons';
import { Box } from 'theme-ui';

const IconStyled = styled(Box)`
    display: inline-flex;

    > svg {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const Icon = ({
    icon,
    size,
    width,
    height,
    alignItems = 'center',
    justifyContent = 'center',
    sx,
    ...props
}) => {
    const pixelMe = (val) =>
        Array.isArray(val) ||
        val === 'auto' ||
        `${val}`.includes('%') ||
        `${val}`.includes('px')
            ? val
            : `${val}px`;
    const svgWidth =
        width !== undefined ? pixelMe(width) : pixelMe(size ?? 'auto');
    const svgHeight =
        height !== undefined ? pixelMe(height) : pixelMe(size ?? 'auto');

    const renderIcon = (icon) => {
        if (allIcons.hasOwnProperty(icon)) {
            const SvgIcon = allIcons[icon];
            return <SvgIcon {...props} />;
        }
        return '';
    };

    return (
        <IconStyled
            width={svgWidth}
            height={svgHeight}
            sx={{
                alignItems,
                justifyContent,
                '& svg': {
                    width: svgWidth,
                    height: svgHeight,
                },
                ...sx,
            }}
            {...props}
        >
            {renderIcon(icon)}
        </IconStyled>
    );
};
