import styled from 'styled-components';

export const StyledStepsNav = styled.div`
    display: flex;
    grid-gap: 16px;
    user-select: none;
    justify-content: center;
    order: -1;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        flex: 100%;
        order: 2;
        grid-gap: 38px;
    }
`;

export const StepNavItem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    font-weight: 500;
    color: #fff;

    & span {
        opacity: ${({ $isActive }) => ($isActive ? '1' : '0.3')};
        transition: opacity 0.3s ease-in-out;
    }

    &::before {
        content: attr(data-index);
        display: block;
        width: 28px;
        height: 28px;
        line-height: 29px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        text-align: center;
        border: 1px solid #fff;
        opacity: ${({ $isActive }) => ($isActive ? '1' : '0.3')};
        transition: opacity 0.3s ease-in-out;
    }

    &:not(:last-child)::after {
        content: '';
        display: block;
        height: 1px;
        width: 25px;
        border-top: 1px dashed rgba(255, 255, 255, 0.3);

        @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
            width: 10px;
        }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        grid-gap: 8px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        display: block;
        transform: translateY(-16px);

        & span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 8px;
        }

        &:not(:last-child)::after {
            display: none;
        }
    }
`;
