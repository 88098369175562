import { Box, Button, Heading, Text, Image } from 'theme-ui';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { DynamicRevaluation } from 'assets/images';
import {
    getQuestionsAsync,
    getStepsAsync,
    startSubmissionAsync,
} from 'features/appThunks';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef, useState } from 'react';
import { ImageList } from '~/Common/ImageList';
import { useEffect } from 'react';
import { Card } from '~/Common/Card';
import { setStep, setUtmSource, setRefSource } from 'features/appSlice';
import { useQuery } from 'hooks/useQuery';

const enableRecaptcha = process.env.REACT_APP_ENV !== 'local';

const IntroUncoupled = ({
    step = null,
    steps,
    startSubmissionAsync,
    getStepsAsync,
    getQuestionsAsync,
    setStep,
    setUtmSource,
    setRefSource
}) => {
    const captchaRef = useRef(null);
    const history = useHistory();
    const [introStep, setIntroStep] = useState(step ?? 1);
    let query = useQuery();

    if (query.get('utm_source') && localStorage.getItem('utm_source') !== query.get('utm_source')) {
        setUtmSource(query.get('utm_source'));
    }
    if (query.get('ref') && localStorage.getItem('ref') !== query.get('ref')) {
        setRefSource(query.get('ref'));
    }

    useEffect(() => {
        if (steps.status === 'idle') {
            getStepsAsync()
                .unwrap()
                .catch(() => {
                    setIntroStep('Error');
                });
        }
    }, [steps.status, getStepsAsync]);

    const startNewSubmission = async () => {
        try {
            const token = captchaRef.current
                ? await captchaRef.current.executeAsync()
                : undefined;

            await startSubmissionAsync(token).unwrap();
            await getQuestionsAsync().then(() => setStep(0));

            history.push('/submission');
        } catch (e) {
            console.log(e);
            toast.error(e.response?.message ?? t('errors.newSubmission'));
        }
    };

    return (
        <>
            <Box>
                <Heading variant="subheading">
                    {t(`pages.intro.step${introStep}.heading`)}
                </Heading>
                <Text
                    variant="large"
                    sx={{ whiteSpace: 'pre-wrap', fontSize: '33px' }}
                >
                    {t(`pages.intro.step${introStep}.subheading`)}
                </Text>
                {introStep === 'Error' && (
                    <Button
                        mt={4}
                        sx={{ minWidth: '190px', display: 'block' }}
                        onClick={() => window.location.reload()}
                    >
                        {t('pages.intro.stepError.button')}
                    </Button>
                )}
                {introStep === 1 && (
                    <>
                        <Box sx={{
                            position: 'relative'
                        }}>
                            <DynamicRevaluation width="75%" mt={4} />
                            <Box sx={{
                                position: 'absolute',
                                right: 0,
                                top: '10%',
                                width: '32%'
                            }}>
                                <Image src="/images/business-value-report-example.png" width="100%" />
                            </Box>
                        </Box>

                        <ImageList
                            sx={{ mt: '32px' }}
                            list={t('pages.intro.step1.list', {
                                returnObjects: true,
                            })}
                        >
                            {t('pages.intro.step1.listContent')}
                        </ImageList>
                        <Button
                            mt={4}
                            sx={{ minWidth: '190px' }}
                            onClick={() => {
                                setIntroStep(2)
                                history.push('/introduction')
                            }}
                        >
                            {t('pages.intro.step1.button')}
                        </Button>
                    </>
                )}
                {introStep === 2 && (
                    <>
                        <Box>
                            {enableRecaptcha && (
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={
                                        process.env
                                            .REACT_APP_RECAPTCHA_SITE_KEY
                                    }
                                    size="invisible"
                                />
                            )}
                            <Box
                                sx={{
                                    position: 'relative',
                                    my: '60px',
                                    '&::before': [
                                        {},
                                        {
                                            display: 'block',
                                            content: '""',
                                            position: 'absolute',
                                            borderLeft:
                                                '1px dashed #00AEEF',
                                            width: 0,
                                            height: '100%',
                                            left: '44px',
                                            py: '30px',
                                            mt: '-30px',
                                        },
                                    ],
                                }}
                            >
                                {Array.isArray(steps.data) &&
                                    steps.data.map((step, index) => (
                                        <Card
                                            key={index}
                                            sx={{
                                                py: '24px',
                                                mb: '32px',
                                                position: 'relative',
                                                zIndex: 0,
                                            }}
                                            bsx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: [3, 4],
                                                flexWrap: [
                                                    'wrap',
                                                    'nowrap',
                                                ],
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    background: '#576b82',
                                                    border: '2px solid #00AEEF',
                                                    borderRadius: '50%',
                                                    height: '40px',
                                                    width: '40px',
                                                    flexShrink: 0,
                                                    textAlign: 'center',
                                                    lineHeight: '38px',
                                                    fontSize: '24px',
                                                    fontWeight: 600,
                                                    zIndex: 90,
                                                    position: 'relative',
                                                    boxShadow:
                                                        '#223d5b 0px 0 10px 12px',
                                                }}
                                            >
                                                {index + 1}
                                            </Box>
                                            <Text
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: '28px',
                                                    flexShrink: 0,
                                                    minWidth: '126px',
                                                    flex: [0, 'unset'],
                                                }}
                                            >
                                                {step.title}
                                            </Text>
                                            <Text
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: '16px',
                                                    lineHeight: 1.4,
                                                }}
                                            >
                                                {step.description}
                                            </Text>
                                        </Card>
                                    ))}
                            </Box>
                            <Button
                                mt={4}
                                sx={{ minWidth: '190px' }}
                                onClick={startNewSubmission}
                            >
                                {t('pages.intro.step2.button')}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export const Intro = connect(({ app }) => ({ steps: app.steps }), {
    startSubmissionAsync,
    getStepsAsync,
    getQuestionsAsync,
    setStep,
    setUtmSource,
    setRefSource
})(IntroUncoupled);
