import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StepsHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    grid-gap: 52px;
    padding: 26px 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        padding: 18px 16px;
    }
`;

export const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    flex: 20%;
    order: -2;
`;
