import { theme } from 'app/theme';
import { useState, useCallback, useEffect } from 'react';

export const useBreakpoint = (index, property = 'max-width') => {
    const [value, setValue] = useState(false);

    const onResize = useCallback(() => {
        setValue(
            theme.breakpoints?.[index] &&
                window.matchMedia(
                    `(${property}: ${theme.breakpoints[index]})`
                ).matches
        );
    }, [index, property]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);

    return value;
};
